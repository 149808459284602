import React, { useState } from 'react';
import { Box, TextField, Button, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        actionButton: {
            height: '100%'
        },
        addplayerrow: {
            marginTop: '20px',
            paddingLeft: '20px'
        }
    })
);

export type AddPlayersProps = {
    addPlayerCallback: Function,
    resetCallback: Function
}

const AddPlayers: React.FC<AddPlayersProps> = (props: AddPlayersProps) => {
    const [newPlayerName, setNewPlayerName] = useState<string>('');
    const classes = useStyles();

    function addPlayerHandler(e: any) {
        if (newPlayerName !== '') {
            props.addPlayerCallback(newPlayerName);
            setNewPlayerName('');
        }
    }

    function newPlayerNameChangeHandler(e: any) {
       setNewPlayerName(e.target.value);
    }

    function resetHandler(e: any) {
        props.resetCallback()
    }

    return (
        <Grid className={classes.addplayerrow} container spacing={0}>
            <Grid item xs={5} sm={6}>
                <TextField label="add a new player..." type='text' onChange={newPlayerNameChangeHandler} value={newPlayerName} />
            </Grid>
            <Grid item xs={3} sm={3}>
                 <Button className={classes.actionButton} variant='outlined' color="default" onClick={addPlayerHandler}>
                    <AddIcon />
                    Add
                </Button>
            </Grid>
            <Grid item xs={4} sm={3}>
                <Button className={classes.actionButton} variant='outlined' color="secondary" onClick={resetHandler}>
                    <AutorenewIcon />
                    Reset
                </Button>
            </Grid>
        </Grid>
    );
}

export default AddPlayers;