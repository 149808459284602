import React from 'react';
import logo from './logo.svg';
import './App.css';
import Scoreboard from './components/scoreboard';

const App: React.FC = () => {
  
    return (
        <Scoreboard></Scoreboard>
    );
}

export default App;
