import React, { useState } from 'react';
import { Player } from '../models/Player';
import BackspaceOutlined from '@material-ui/icons/BackspaceOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, TextField, Button, Grid } from '@material-ui/core';

type StyleProps = {
    backgroundColor: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    playerrow: (props: StyleProps) => ({
        height: '100px',
        lineHeight: '100px',
        backgroundColor: props.backgroundColor
    }),
    roundinput: {
        verticalAlign: 'middle'
    },
    namedisplay: {
        paddingLeft: '20px'
    },
    addbutton: {
        width: '100%',
        height: '100%'
    },
  }),
);

export type PlayerDisplayProps = {
    player: Player,
    backgroundColor: string,
    addRoundScoreCallback: Function
};

const PlayerDisplay: React.FC<PlayerDisplayProps> = (props: PlayerDisplayProps) => {
    const player = props.player;
    const [roundScore, setRoundScore] = useState<number>(0);
    const classes = useStyles({backgroundColor: props.backgroundColor});
    
    function updateScoreClickHandler(e: any) {
        props.addRoundScoreCallback(player, roundScore)    
        setRoundScore(0)
    }

    function roundScoreChangeHandler(e: any) {
        try {
            setRoundScore(parseInt(e.target.value));
        } catch(error) {
           setRoundScore(0);
        }
    }

    return (
        <Grid className={classes.playerrow} container spacing={0}>
            <Grid item xs={5}>
                <Box className={classes.namedisplay}>
                    {player.name}
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box>
                    {player.score}
                </Box>
            </Grid>
            <Grid item xs={2}>
                <TextField className={classes.roundinput} onChange={roundScoreChangeHandler} value={roundScore || ''} type='number' />
            </Grid>
            <Grid item xs={3}>
                <Button className={classes.addbutton} color='primary' onClick={updateScoreClickHandler}>
                    <AddCircleOutlineOutlinedIcon />
                </Button>
            </Grid>
        </Grid>
    )
}

export default PlayerDisplay;