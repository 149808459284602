import React from 'react';
import AddPlayers from './add-players';
import { Player } from '../models/Player';
import { useState } from 'react';
import PlayerDisplay from './player-display';
import { Box, Typography, Toolbar, AppBar } from '@material-ui/core';
import ScoreIcon from '@material-ui/icons/Score';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        appcontainer: {
            height: '100%',
            maxWidth: '500px',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        appicon: {
            marginRight: '20px'
        }
    })
);

const Scoreboard: React.FC = () => {
    const [players, setPlayers] = useState<Array<Player>>([]);
    const classes = useStyles();

    function addNewPlayer(playerName: string) {
        let newPlayer: Player = {
            name: playerName,
            score: 0,
            id: players.length + 1
        };

        players.push(newPlayer);
        setPlayers(Array.from(players));
    }

    function reset() {
        players.forEach(player => {
            player.score = 0;
        });

        setPlayers(Array.from(players));
    }

    function addPlayerRoundScoreCallback(player: Player, roundScore: number) {
        let playerToUpdate = players.find(p => p === player);

        if (playerToUpdate) {
            playerToUpdate.score += roundScore;
        } else {
            console.log("unable to update player: " + player.name + "(" + player.id + ")");
        }
    }

    return (
        <Box className={classes.appcontainer}>
            <AppBar position='static'>
                <Toolbar>
                    <ScoreIcon className={classes.appicon} />
                    <Typography variant="h6" noWrap>
                        Scoremaster 5000
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box>
                {players.map((player, index) => ( 
                    <PlayerDisplay 
                        backgroundColor={index % 2 === 0 ? '#f5f6fa' : '#fffff'}
                        player={player} 
                        addRoundScoreCallback={addPlayerRoundScoreCallback} />
                ))}
            </Box>
            <Box>
                <AddPlayers addPlayerCallback={addNewPlayer} resetCallback={reset}></AddPlayers>
            </Box>
        </Box>
    )
}

export default Scoreboard;